*,::before,::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li{
  list-style: none;
}
img{
  width: 100%;
}

a{
  text-decoration: none;
  color: black;
}
p {
  padding: 10px 0;

}
h1,h2,h3,h4,h5,h6{
  padding: 10px 0;
}


.greenText{
  color: #0fd2a8;
}
.bgGreen {
  background-color: #0fd2a8;
}

.blueText{
  color: #0d3675;
}
.bgBlue {
  background-color: #0d3675;
}


.grid{
 display: grid;
 grid-template-columns: repeat(2,1fr); 
 justify-content: center;

}

.max-width{
  width: 90%;
}

.border-radius{
  border-radius: 25px;
  
}
 .hero-h1{
  max-width: 400px;
 }
    /* HERO */

  .hero{
    background: url('./images/image5.jpg') no-repeat center center/cover;
    overflow: hidden;
    
  } 
  
  .hero-container{
    position: relative;
  }

  .hero-container::before{
    content: "";
    position: absolute;
    width: 400px;
    height: 650px;
    right: -220px;
    border-radius: 48%;
    top: -65px;
    transform: rotate(20deg);
    background-color: black;
    background-color: #0fd2a8;

  }

.bold-h1{
  font-size: 2.6rem;
  font-weight: bold;
}
.woman{
  max-height: 600px;
  object-fit: fill;
  position: relative;
  width: 100%;
  right: -330px;
  top: -90px;
}

.largest{
  padding-left: 65px;
}
.text-dark{
  width: 55%;
  text-align: left;
 padding-left: 0;
  
  
}

.top-circle{
  height:130px;
   width: 210px;
   border-radius: 50%;
    position: absolute;
   top: -80px; 
    right: 100px}




  
@media(max-width: 1200px){
  .woman{
    right: -100px;
  }
}
  
@media(max-width: 900px){
  .woman{
    right: -100px;
  }
}


@media(max-width: 830px){

  .woman{
   
    
    right: -60px;
    top: -70px;
  }
  .largest{
    padding-left: 20px;
  }
}







  @media(max-width: 800px){
    .hero{
      background: white;
      height: auto;
      margin-top: 22px;
    }

    .woman{
      right: 0;
      top: 20px;
      max-height: 450px;
      margin-bottom: 160px;
      transform: rotate(20deg);
    }

    .grid{
      grid-template-columns: 1fr;
  
    }
    
    .largest{
      padding-left: 10px;
       order: 2;
       margin-top: 100px;
       position: relative;
       z-index: 1;
      
    }
    
    .largest::after{
      content: "+";
      font-size: 5rem;
      color: #0d3675;
      width: 130px;
      height: 130px;
      background-color: #54f6bd;
      position: absolute;
      top: -185px;
      right: 45%;
      border-radius: 50%;
    }

    

    .text-dark{
      width: 100%;
      text-align: left;
     padding-left: 0;
      
      
    }


    .max-width{
      margin-left: 50px;
      margin-bottom: 50px;
    }
    
.top-circle{
  height:130px;
   width: 210px;
   border-radius: 50%;
    position: absolute;
   top: -105px; 
    right: 60px
  }
.flex-col{
  display: flex;
  flex-direction: column;
  width: 160px;
}
.mobile-center{
  margin: 0 auto;
}
  .hero-container::before{
    content: "";
    position: absolute;
    width: 800px;
    height: 1000px;
    right: -270px;
    border-radius: 48%;
    top: -30px;
    transform: rotate(45deg);
    background-color: black;
    background-color: #0fd2a8;
    z-index: 0;
  }
  .mobile-pad{
    margin-left: 15px;
  }
  }
@media(max-width:550px){
  .hero-container::before{
    content: "";
    position: absolute;
    width: 550px;
    height: 830px;
    right: -220px;
    border-radius: 48%;
    top: -15px;
    transform: rotate(15deg);
    background-color: black;
    background-color: #0fd2a8;
    z-index: 0;
  } 

  
  .woman{
    right: 30px;
    top: -30px;
  }

  .mobile-pad{
    margin-left: 0;
    margin-bottom: 100px;
  }
}

